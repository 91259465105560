<template>
  <section>
    <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="w-full flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="text-lg font-bold">CONFIGURACIÓN GLOBAL OV</p>
      </div>
      <div class="w-1/2 p-4 flex flex-col">
        <section class="flex items-center gap-2">
          <span class="w-1/2">Maximo de días previos para la fecha de entrega: </span>
          <InputNumber
            v-model="config.days_previous_docduedate"
            class="p-inputtext-sm"
          />
        </section>
      </div>
      <div class="w-1/2 p-4 flex flex-col">
        <section class="flex items-center gap-2">
          <span class="w-1/2">Maximo de días posteriores para la fecha de entrega: </span>
          <InputNumber
            v-model="config.days_next_docduedate"
            class="p-inputtext-sm"
          />
        </section>
      </div>
      <div class="w-1/2 p-4 flex flex-col">
        <section class="flex items-center gap-2">
          <span class="w-1/2">Habilitar edición para fecha de contabilización y documento: </span>
          <Checkbox v-model="config.enable_editing_for_posting_date_and_document" :binary="true"/>
        </section>
      </div>
      <div class="w-1/2 p-4 flex flex-col">
        <section class="flex items-center gap-2">
          <span class="w-1/2">Fecha minima (Prescripción, Autorización, Acta CTC, Solicitud Acta CTC): </span>
          <input
            type="date"
            v-model="config.minimum_date"
            class="p-inputtext p-inputtext-sm"
          />
        </section>
      </div>
      <div class="w-1/2 p-4 flex flex-col">
        <section class="flex items-center gap-2">
          <span class="w-1/2">Fecha maxima (Prescripción, Autorización, Acta CTC, Solicitud Acta CTC): </span>
          <input
            type="date"
            v-model="config.maximum_date"
            class="p-inputtext p-inputtext-sm"
          />
        </section>
      </div>
      <div class="flex justify-center">
        <Button
          label="Guardar"
          class="p-button-success p-button-sm"
          @click="saveConfig"
        />
      </div>
    </div>
  </section>
</template>
<script setup>
import Swal from 'sweetalert2'
import { onMounted, ref } from 'vue'
import ConfigSapService from '../../../../../services/config-sap.service'

// Services
const _ConfigSapService = new ConfigSapService()

// Referencias
const config = ref({
  days_previous_docduedate: 0,
  days_next_docduedate: 0,
  enable_editing_for_posting_date_and_document: false,
  minimum_date: null,
  maximum_date: null,
  difference_of_maximum_days_to_generate_OV_when_it_is_mipres: 0,
  difference_of_maximum_days_to_generate_OV_when_it_is_mipres_and_the_permit_has_been_assigned: 0,
  difference_of_minium_days_to_create_mipres_ov: 0
})

// Metodos
const saveConfig = () => {
  _ConfigSapService.update(config.value).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Configuración actualizada'
    })
  })
}

// OnMounted
onMounted(async () => {
  _ConfigSapService.get().then(({ data }) => {
    for (const index of Object.keys(config.value)) {
      if (['enable_editing_for_posting_date_and_document'].includes(index)) {
        config.value[index] = JSON.parse(data[index].Value)
      } else {
        config.value[index] = data[index].Value
      }
    }
  })
})
</script>
<style>
.p-dropdown-label {
  font-size: 0.75rem !important
}

.tipoId .p-dropdown-trigger {
  display: none !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-input-placeholder { /* Edge */
  color: gray;
}

::-webkit-scrollbar {
  width: 0.4rem;
}
</style>
